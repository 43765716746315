// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-party-js": () => import("/opt/build/repo/src/pages/our-party.js" /* webpackChunkName: "component---src-pages-our-party-js" */),
  "component---src-pages-our-story-js": () => import("/opt/build/repo/src/pages/our-story.js" /* webpackChunkName: "component---src-pages-our-story-js" */),
  "component---src-pages-questions-js": () => import("/opt/build/repo/src/pages/questions.js" /* webpackChunkName: "component---src-pages-questions-js" */),
  "component---src-pages-registry-js": () => import("/opt/build/repo/src/pages/registry.js" /* webpackChunkName: "component---src-pages-registry-js" */),
  "component---src-pages-save-the-date-js": () => import("/opt/build/repo/src/pages/save-the-date.js" /* webpackChunkName: "component---src-pages-save-the-date-js" */),
  "component---src-pages-update-js": () => import("/opt/build/repo/src/pages/update.js" /* webpackChunkName: "component---src-pages-update-js" */),
  "component---src-pages-what-to-do-art-and-architecture-js": () => import("/opt/build/repo/src/pages/what-to-do/art-and-architecture.js" /* webpackChunkName: "component---src-pages-what-to-do-art-and-architecture-js" */),
  "component---src-pages-what-to-do-food-and-drinks-js": () => import("/opt/build/repo/src/pages/what-to-do/food-and-drinks.js" /* webpackChunkName: "component---src-pages-what-to-do-food-and-drinks-js" */),
  "component---src-pages-what-to-do-index-js": () => import("/opt/build/repo/src/pages/what-to-do/index.js" /* webpackChunkName: "component---src-pages-what-to-do-index-js" */),
  "component---src-pages-what-to-do-nature-and-hikes-js": () => import("/opt/build/repo/src/pages/what-to-do/nature-and-hikes.js" /* webpackChunkName: "component---src-pages-what-to-do-nature-and-hikes-js" */),
  "component---src-pages-where-to-stay-js": () => import("/opt/build/repo/src/pages/where-to-stay.js" /* webpackChunkName: "component---src-pages-where-to-stay-js" */)
}

